import React, { Component } from "react";
import {
  Spin,
  Modal,
  Button,
  Input,
  Row,
  Col,
  Tabs,
  Select,
  Radio,
} from "antd";
import { Query, compose } from "react-apollo";
import queryString from "query-string";
import _get from "lodash/get";
import _uniq from "lodash/uniq";
import moment from "moment";
import { withTranslation } from "react-i18next";
import "./css/style.css";
import {
  ButtonSummiteventCumulative,
  ButtonCumulative,
  ButtonCumulativeTimeOpen,
} from "../../../styled-components/event.js";
import {
  TitleEvent,
  Tableevent,
  FinisherWallCumulative,
} from "../../../styled-components/event.js";
import Footer from "~/V3/components/footer";
import Header from "~/V3/components/header";
import provinces from "~/V3/data/provinces.json";
import regions from "~/V3/data/regions.json";
import RegionsChart from "./components/region/regionsChart";
import NormalChart from "./components/normal";
import Region from "./components/region";
import Box from "./components/box";

import raceClient from "~/apollo-clients/race";
import eventRunFromSlugQuery from "~/graphql/queries/eventRunFromSlug.gql";
import _sortBy from "lodash/sortBy";
import eventRunsQuery from "~/graphql/queries/eventRuns.gql";
import sumDistanceByGroupEventQuery from "~/graphql/queries/sumDistanceByGroupEvent.gql";
import withTracker from "~/hocs/withTracker";
import totalDistanceParticipant from "~/graphql/queries/totalDistanceParticipant.gql";
import totalDistanceParticipantStat from "~/graphql/queries/totalDistanceParticipantStat.gql";

const PER_PAGE = 200;
const { TabPane } = Tabs;
const Search = Input.Search;
const Normal = withTranslation()(NormalChart);

class Cumulative extends Component {
  state = {
    isOpen: [],
    runnerRecords: [],
    visible: false,
    page: 1,
    bibSearch: "",
    value: 1000,
    skip: 0,
    buttonSearch: false,
    raceId: "",
    nameRace: "All",
    charts: false,
    selectedProvinces: "",
    selectedRegions: "",
    region: false,
    startDate: "",
    endDate: "",
    Popover: false,
    visible2: false,
    preStart: "",
    preEnd: "",
    activeKey: "",
  };
  componentDidMount() {
    this.interval = setInterval(() => {
      const navbar = this.navstk;
      const sticky = navbar && navbar.offsetTop;
      if (window.pageYOffset > sticky) {
        navbar && navbar.classList.add("sticky");
      } else {
        navbar && navbar.classList.remove("sticky");
      }
    }, 1000);
  }

  handlePageChange = (page) => {
    this.setState({ page });
  };
  handleSearch = (searchBib) => {
    this.props.history.push(`/${this.props.match.params.slug}/${searchBib}`);
  };

  handleRunClick = ({ runnerImage, activityImage }) => (e) => {
    Modal.warning({
      width: "50vw",
      maskClosable: true,
      bodyStile: { padding: "6px" },
      content: (
        <React.Fragment>
          <div>
            {runnerImage && (
              <img
                alt="runner"
                src={_get(runnerImage, "original.url")}
                style={{ maxWidth: "100%" }}
              />
            )}
            {activityImage && (
              <img
                alt="activity"
                src={_get(activityImage, "original.url")}
                style={{ maxWidth: "100%" }}
              />
            )}
          </div>
        </React.Fragment>
      ),
    });
  };

  handleTabs = (key) => {
    const raceId = key;
    this.setState({
      raceId: raceId,
      charts: false,
      selectedProvinces: "",
      selectedRegions: "",
      activeKey: raceId,
    });
  };

  handleChangeProvinces = (value) => {
    this.setState({
      charts: true,
      selectedProvinces: value,
    });
    if (!value && !this.state.selectedRegions) {
      this.setState({
        charts: false,
      });
    }
  };

  handleChangeRegions = (value) => {
    this.setState({
      charts: true,
      selectedRegions: value,
      selectedProvinces: "",
    });
    if (!value && !this.state.selectedProvinces) {
      this.setState({
        charts: false,
      });
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  handleAllChange = () => {
    this.setState({ startDate: "", endDate: "" });
  };

  render() {
    const { match } = this.props;
    const { search } = this.props.location;
    const { p: page } = queryString.parse(search);
    const { s: searchBib } = queryString.parse(search);
    const hideSlug = ["SG19-staging"];
    const { t } = this.props;
    return (
      <Query
        query={eventRunFromSlugQuery}
        client={raceClient}
        variables={{
          slug: match.params.slug,
        }}
      >
        {({ data, loading, error }) => {
          if (loading) {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <Spin tip="Loading..." />
              </div>
            );
          }

          if (error) {
            return <div>Error</div>;
          }
          if (!data.eventRunFromSlug) {
            return <div>eventRunFromSlugQuery not found</div>;
          }
          if (
            !_get(data, "eventRunFromSlug.settings.virtualRun.finisherwall")
          ) {
            return window.location.replace(`/${match.params.slug}/submit`);
          }
          const { eventRunFromSlug: event } = data;
          const timeDay = moment(
            data.eventRunFromSlug.settings.virtualRun.period.start
          ).locale(this.props.i18n.language);
          const timeDayEnd = moment(
            data.eventRunFromSlug.settings.virtualRun.period.end
          ).locale(this.props.i18n.language);
          const timeOpen = moment().isBetween(timeDay, timeDayEnd);
          const slug = event.slug;
          const participantFields =
            _get(event, "settings.participant.fields", []) || [];
          const regFields = participantFields
            .filter((f) => f.enabled)
            .reduce((result, field) => {
              result[field.name] = field;
              return result;
            }, {});
          const ageCategoriesRace = _get(event, "races").find((race) => {
            return (
              _get(race, "deleted") !== true &&
              _get(race, "ageCategories.length") > 0
            );
          });
          const ageCategories = _get(ageCategoriesRace, "ageCategories");
          const isAgeEnabled =
            _get(ageCategoriesRace, "ageCategories.length") > 0;
          let ageRanges = null;
          if (isAgeEnabled) {
            ageRanges = ageCategories.map((cat) => {
              if (cat.min === null) {
                return `< ${cat.max + 1}`;
              }
              if (cat.max === null) {
                return `> ${cat.min - 1}`;
              }
              return `${cat.min || ""} - ${cat.max || ""}`;
            });
            ageRanges = _uniq(ageRanges);
          }

          const chartsMode = event.settings.virtualRun.chartsMode;
          const splitRaces = event.settings.virtualRun.splitRaces;
          const isOpening = (datas) => {
            const result = datas.some(
              (data) =>
                moment().isBetween(data.start, data.end) ||
                moment().isBetween(data.preRegisterStart, data.preRegisterEnd)
            );
            return result;
          };
          const eventRace = isOpening(event.periods);
          const finisherCoverUrl = event.finisherCoverUrl;
          const raceTeam = event.settings.virtualRun.raceTeam;
          const isAutoApproved = event.settings.virtualRun.isAutoApproved;
          const rankMode = event.settings.virtualRun.rankMode;
          const eventGender = event.settings.ticket.gender
          const UA = navigator.userAgent || navigator.vendor || window.opera;
          const isLINE = UA.indexOf("Line/") > -1;
          const isLineLogin = _get(event, "isLineLogin") && isLINE;
          let registrationTypes = _get(event, "registrationTypes");
          registrationTypes = registrationTypes.filter((type) => !type.optionHidden)
          const extraMessages = () => {
            let content = `
          
        <p>การวิ่งออนไลน์คือ วิ่งระยะ 42.195km ภายใน 7 ชั่วโมง เป็นการวิ่งรวดเดียว จากที่ไหนก็ได้ระหว่างวันอาทิตย์ที่ 8 ถึง 15 พฤศจิกายน 2563</p>
        
        <p>กำหนดส่งผล ระหว่างวันอาทิตย์ที่ 8 พฤศจิกายน 2563 เวลา 9.00น. ถึง 17 พฤศจิกายน 2563 ก่อนเวลา 23.59น.</p>
        
        <p>กรณีส่งผลการวิ่งแบบออนไลน์ครบถ้วน นักวิ่งจะได้รับ เสื้อPUMA + บิบ + เสื้อCEP + เหรียญรางวัล</p>
        
        <p>กรณีไม่ได้ส่งผล หรือ ผลที่ส่งไม่ถูกต้องตามเงื่อนไข นักวิ่งจะได้รับเฉพาะ เสื้อPUMA + บิบ</p>
        
        <p>จัดส่งของให้ทางไปรษณีย์ภายในวันศุกร์ที่ 27 พฤศจิกายน 2563</p>
        
        <p>สงวนสิทธิ์ให้เฉพาะนักวิ่งที่ทำเรื่องไม่สะดวกเข้าร่วมงานและขอเปลี่ยนเป็นการวิ่งแบบออนไลน์ไว้แล้วเท่านั้น</p>
        
          `;
            content = `
          <p data-renderer-start-pos="27" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>BANGSAEN21 &ndash; ONLINE RUNNERส่งผล 20 ธ.ค. 2020 - 5 ม.ค. 2021 23:59 น.</p>

          <p data-renderer-start-pos="102" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>&nbsp;<strong data-renderer-mark="true">การวิ่งแบบออนไลน์</strong></p>

          <p data-renderer-start-pos="122" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>วิ่งที่ไหนก็ได้&nbsp;ให้ครบระยะ&nbsp;21.1กม.&nbsp;ภายในเวลา&nbsp;3:30ชั่วโมง&nbsp;รวดเดียว&nbsp;(ห้ามสะสม)</p>

          <p data-renderer-start-pos="200" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>นับผลการวิ่งที่วิ่งระหว่างวันที่&nbsp;20&nbsp;ธ.ค. 2020 -&nbsp;3&nbsp;ม.ค. 2021&nbsp;เท่านั้น</p>

          <p data-renderer-start-pos="270" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>(ขยายเวลาจากเดิม 27 ธ.ค. 2020)</p>

          <p data-renderer-start-pos="304" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'><strong data-renderer-mark="true">ส่งผลเข้าระบบ</strong> 20 ธ.ค. 2020 - 5 ม.ค. 2021 ภายใน 23:59 น.</p>

          <p data-renderer-start-pos="361" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>(ขยายเวลาจากเดิม 29 ธ.ค. 2020)</p>

          <p data-renderer-start-pos="395" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'><strong data-renderer-mark="true">กรณีส่งผลการวิ่งครบถ้วน&nbsp;:</strong> จะได้รับเสื้อวิ่ง Compressport + บิบ + เหรียญรางวัล + Finisher Gift</p>

          <p data-renderer-start-pos="490" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'><strong data-renderer-mark="true">กรณีไม่ได้ส่งผล&nbsp;หรือ&nbsp;ส่งผลไม่ครบตามกติกา&nbsp;:</strong> จะได้รับเฉพาะเสื้อวิ่ง Compressport + บิบเท่านั้น</p>

          <p data-renderer-start-pos="584" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'><strong data-renderer-mark="true">จัดส่งของ</strong> 11 ม.ค. 2021</p>

          <p data-renderer-start-pos="608" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>หมายเหตุ;</p>

          <p data-renderer-start-pos="621" style="margin: 0px; padding: 0px; font-size: 1em; line-height: 1.714; font-weight: normal; letter-spacing: -0.005em;">&nbsp; 1. สงวนสิทธิ์ให้เฉพาะนักวิ่งที่ทำเรื่องเปลี่ยนเป็นวิ่งออนไลน์ และ นักวิ่งที่รับบิบแล้วแต่ไม่ได้มาออก start เท่านั้น</p>

          <p data-renderer-start-pos="737" style="margin: 0px; padding: 0px; font-size: 1em; line-height: 1.714; font-weight: normal; letter-spacing: -0.005em;">&nbsp; 2. นักวิ่ง online Runer ยังคงได้สิทธิ์นักวิ่งเก่า</p>

          <p data-renderer-start-pos="787" style="margin: 0px; padding: 0px; font-size: 1em; line-height: 1.714; font-weight: normal; letter-spacing: -0.005em;">&nbsp; 3. นักวิ่งบัตรวีไอพี และ นักวิ่งที่มีสั่งสินค้า pre-order ไว้ ทีมงานจะจัดส่งของทั้งหมดให้พร้อมกัน</p>



          `;
            content = `
          <p data-renderer-start-pos="27" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>BANGSAEN21 &ndash; ONLINE RUNNERส่งผล 20 ธ.ค. 2020 - 5 ม.ค. 2021 23:59 น.</p>

          <p data-renderer-start-pos="102" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>&nbsp;<strong data-renderer-mark="true">การวิ่งแบบออนไลน์</strong></p>

          <p data-renderer-start-pos="122" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>วิ่งที่ไหนก็ได้&nbsp;ให้ครบระยะ&nbsp;21.1กม.&nbsp;ภายในเวลา&nbsp;3:30ชั่วโมง&nbsp;รวดเดียว&nbsp;(ห้ามสะสม)</p>

          <p data-renderer-start-pos="200" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>นับผลการวิ่งที่วิ่งระหว่างวันที่&nbsp;20&nbsp;ธ.ค. 2020 -&nbsp;3&nbsp;ม.ค. 2021&nbsp;เท่านั้น</p>

          <p data-renderer-start-pos="270" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>(ขยายเวลาจากเดิม 27 ธ.ค. 2020)</p>

          <p data-renderer-start-pos="304" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'><strong data-renderer-mark="true">ส่งผลเข้าระบบ</strong> 20 ธ.ค. 2020 - 5 ม.ค. 2021 ภายใน 23:59 น.</p>

          <p data-renderer-start-pos="361" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>(ขยายเวลาจากเดิม 29 ธ.ค. 2020)</p>

          <p data-renderer-start-pos="395" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'><strong data-renderer-mark="true">กรณีส่งผลการวิ่งครบถ้วน&nbsp;:</strong> จะได้รับเสื้อวิ่ง Compressport + บิบ + เหรียญรางวัล + Finisher Gift</p>

          <p data-renderer-start-pos="490" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'><strong data-renderer-mark="true">กรณีไม่ได้ส่งผล&nbsp;หรือ&nbsp;ส่งผลไม่ครบตามกติกา&nbsp;:</strong> จะได้รับเฉพาะเสื้อวิ่ง Compressport + บิบเท่านั้น</p>

          <p data-renderer-start-pos="584" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'><strong data-renderer-mark="true">จัดส่งของ</strong> 11 ม.ค. 2021</p>

          <p data-renderer-start-pos="608" style='margin: 1.143rem 0px 0px; padding: 0px; font-size: 14px; line-height: 1.714; font-weight: 400; letter-spacing: -0.005em; color: rgb(23, 43, 77); font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: pre-wrap; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;'>หมายเหตุ;</p>

          <p data-renderer-start-pos="621" style="margin: 0px; padding: 0px; font-size: 1em; line-height: 1.714; font-weight: normal; letter-spacing: -0.005em;">&nbsp; 1. สงวนสิทธิ์ให้เฉพาะนักวิ่งที่ทำเรื่องเปลี่ยนเป็นวิ่งออนไลน์ และ นักวิ่งที่รับบิบแล้วแต่ไม่ได้มาออก start เท่านั้น</p>

          <p data-renderer-start-pos="737" style="margin: 0px; padding: 0px; font-size: 1em; line-height: 1.714; font-weight: normal; letter-spacing: -0.005em;">&nbsp; 2. นักวิ่ง online Runer ยังคงได้สิทธิ์นักวิ่งเก่า</p>

          <p data-renderer-start-pos="787" style="margin: 0px; padding: 0px; font-size: 1em; line-height: 1.714; font-weight: normal; letter-spacing: -0.005em;">&nbsp; 3. นักวิ่งบัตรวีไอพี และ นักวิ่งที่มีสั่งสินค้า pre-order ไว้ ทีมงานจะจัดส่งของทั้งหมดให้พร้อมกัน</p>

          `;
            return (
              <div>
                <div
                  style={{ fontSize: "small" }}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
            );
          };
          const races = _get(event, "races", []).filter((r) => !r.deleted);
          return (
            <React.Fragment>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  className="wrap"
                  style={{ width: "1250px", maxWidth: "100%" }}
                >
                  <div>
                    {!isLineLogin && <Header />}
                    {!isLineLogin && <div style={{ marginTop: "90px" }} />}
                    <div>
                      <div
                        className="head-page-cumulative"
                        style={{
                          backgroundColor: "#000",
                          maxHeight: "100%",
                          height: "500px",
                        }}
                      >
                        <Row
                          type="flex"
                          justify="center"
                          style={{ width: "100%" }}
                        >
                          <Col xs={24} lg={12}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <TitleEvent>
                                {finisherCoverUrl ? (
                                  <img
                                    className="img-cover"
                                    src={finisherCoverUrl}
                                    alt="Finishrwall"
                                    style={{ width: "90%", zIndex: "9" }}
                                  />
                                ) : (
                                  <img
                                    src="/finisherwall.jpeg"
                                    alt="Finishrwall"
                                    style={{ width: "90%", zIndex: "9" }}
                                  />
                                )}
                              </TitleEvent>
                            </div>
                          </Col>
                          <Col
                            xs={24}
                            lg={12}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                }}
                              >
                                <h3
                                  className="font-name-FinisherWall"
                                  style={{
                                    color: "#fff",
                                    marginLeft: "40px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {_get(event, "name.en")}
                                </h3>
                              </div>
                              <Row
                                type="flex"
                                justify="start"
                                style={{
                                  marginLeft: "40px",
                                  marginBottom: "10px",
                                }}
                              >
                                <Col
                                  xs={24}
                                  lg={12}
                                  style={{ display: "contents" }}
                                >
                                  <h2
                                    className="font-time-FinisherWall"
                                    style={{ color: "#fff" }}
                                  >
                                    {moment(timeDay)
                                      .locale(this.props.i18n.language)
                                      .format("DD MMM YYYY")}
                                  </h2>
                                </Col>
                                <Col
                                  xs={24}
                                  lg={12}
                                  style={{ display: "contents" }}
                                >
                                  <h2
                                    className="font-time-FinisherWall"
                                    style={{
                                      marginLeft: "10px",
                                      color: "#fff",
                                    }}
                                  >
                                    {" "}
                                    -{" "}
                                    {moment(timeDayEnd)
                                      .locale(this.props.i18n.language)
                                      .format("DD MMM YYYY")}
                                  </h2>
                                </Col>
                              </Row>
                              {!hideSlug.includes(event.slug) && !isLineLogin && (
                                <ButtonCumulative>
                                  {eventRace && (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {![
                                        "teevr2",
                                        "BS21-2020-Onlinerunner",
                                      ].includes(event.slug) &&
                                        !isLineLogin && (
                                          <ButtonSummiteventCumulative>
                                            <Button
                                              type="default"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              href={`https://race.thai.run/${event.slug}`}
                                              shape="round"
                                              className="btn-affect-cumulative"
                                              style={{
                                                height: "50px",
                                                padding: "5px 40px",
                                              }}
                                              size="large"
                                              block
                                            >
                                              <span
                                                className="resultfont"
                                                style={{ fontSize: "16px" }}
                                              >
                                                {t("finisherwall.register")}
                                              </span>
                                            </Button>
                                          </ButtonSummiteventCumulative>
                                        )}
                                    </div>
                                  )}
                                  {timeOpen ? (
                                    <ButtonCumulativeTimeOpen>
                                      <ButtonSummiteventCumulative>
                                        <Button
                                          type="default"
                                          href={`/${event.slug}/submit`}
                                          shape="round"
                                          className="btn-send-cumulative"
                                          style={{
                                            height: "50px",
                                            padding: "5px 40px",
                                          }}
                                          size="large"
                                          block
                                        >
                                          <span
                                            className="resultfont"
                                            style={{ fontSize: "16px" }}
                                          >
                                            {t("finisherwall.resultSubmit")}
                                          </span>
                                        </Button>
                                      </ButtonSummiteventCumulative>
                                    </ButtonCumulativeTimeOpen>
                                  ) : (
                                    ""
                                  )}
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <ButtonSummiteventCumulative>
                                      <Button
                                        type="default"
                                        onClick={this.showModal}
                                        shape="round"
                                        className="btn-send-cumulative"
                                        style={{
                                          height: "50px",
                                          padding: "0 40px",
                                        }}
                                        size="large"
                                        block
                                      >
                                        <span
                                          className="resultfont"
                                          style={{ fontSize: "16px" }}
                                        >
                                          {t("finisherwall.resultSearch")}
                                        </span>
                                      </Button>
                                      <Modal
                                        visible={this.state.visible}
                                        onOk={this.handleOk}
                                        onCancel={this.handleCancel}
                                        footer={""}
                                      >
                                        <div style={{ marginTop: "40px" }}>
                                          <Search
                                            id="myInput"
                                            defaultValue={searchBib}
                                            onSearch={this.handleSearch}
                                            placeholder={t(
                                              "finisherwall.bibSearch"
                                            )}
                                            title="Type in a name"
                                            size="large"
                                          />
                                        </div>
                                      </Modal>
                                    </ButtonSummiteventCumulative>
                                  </div>
                                </ButtonCumulative>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <FinisherWallCumulative>
                      <Row type="flex" justify="center">
                        <Col xs={24} lg={12} style={{ display: "contents" }}>
                          {["BS21-2020-Onlinerunner", "teevr2"].includes(
                            event.slug
                          ) && (
                            <div style={{ margin: "20px" }}>
                              {extraMessages({})}
                            </div>
                          )}
                        </Col>
                      </Row>

                      {splitRaces ? (
                        <Box eventId={event._id} />
                      ) : (
                        <Query
                          query={sumDistanceByGroupEventQuery}
                          client={raceClient}
                          variables={{
                            eventId: event._id,
                          }}
                        >
                          {(props) => {
                            const { data, loading, error } = props;
                            if (loading) {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Spin tip="Loading..." />
                                </div>
                              );
                            }
                            if (error) {
                              return <div>Error</div>;
                            }
                            if (!data.sumDistanceByGroupEvent) {
                              return <div>Runs not found</div>;
                            }
                            const sumDistanceByGroupEvent$ = _get(
                              data,
                              "sumDistanceByGroupEvent"
                            );
                            const sumDistanceByGroupEvent =
                              _get(event, "slug") === "tucufootrun"
                                ? sumDistanceByGroupEvent$.map((s) => {
                                    if (
                                      `${s.raceId}` ===
                                      "61c580255c36d62e70d95d43"
                                    ) {
                                      return {
                                        ...s,
                                        totalDistance:
                                          (_get(s, "totalDistance") || 0) +
                                          12000 +
                                          8000 +
                                          12000 +
                                          20000 +
                                          (moment().isAfter(
                                            "2022-03-25T09:01:01.359+0700"
                                          )
                                            ? 10000
                                            : 0) +
                                          (moment().isAfter(
                                            "2022-03-25T17:01:01.359+0700"
                                          )
                                            ? 10000
                                            : 0) +
                                          (moment().isAfter(
                                            "2022-03-26T09:01:01.359+0700"
                                          )
                                            ? 10000
                                            : 0) +
                                          (moment().isAfter(
                                            "2022-03-27T09:01:01.359+0700"
                                          )
                                            ? 10000
                                            : 0),
                                      };
                                    }
                                    if (
                                      `${s.raceId}` ===
                                      "61c55417e032395040a31527"
                                    ) {
                                      return {
                                        ...s,
                                        totalDistance:
                                          (_get(s, "totalDistance") || 0) +
                                          10000 +
                                          5000 +
                                          10000 +
                                          22000 +
                                          (moment().isAfter(
                                            "2022-03-25T09:01:01.359+0700"
                                          )
                                            ? 10000
                                            : 0) +
                                          (moment().isAfter(
                                            "2022-03-25T17:01:01.359+0700"
                                          )
                                            ? 12000
                                            : 0) +
                                          (moment().isAfter(
                                            "2022-03-26T09:01:01.359+0700"
                                          )
                                            ? 10000
                                            : 0) +
                                          (moment().isAfter(
                                            "2022-03-27T09:01:01.359+0700"
                                          )
                                            ? 10000
                                            : 0),
                                      };
                                    }
                                    return s;
                                  })
                                : sumDistanceByGroupEvent$;

                            const totalRunDistance = parseFloat(
                              sumDistanceByGroupEvent.reduce(
                                (i, c) => i + c.totalDistance,
                                0
                              ) || 0
                            ).toLocaleString();
                            const totalRunScoreAll = parseFloat(
                              sumDistanceByGroupEvent.reduce(
                                (i, c) => i + c.totalRunningScore,
                                0
                              ) || 0
                            ).toLocaleString();
                            const totalRunScore = parseFloat(
                              sumDistanceByGroupEvent.reduce(
                                (i, c) => i + c.totalRunningScoreAll,
                                0
                              ) || 0
                            ).toLocaleString();
                            return (
                              <React.Fragment>
                                <Query
                                  query={totalDistanceParticipantStat}
                                  client={raceClient}
                                  variables={{
                                    eventId: event._id,
                                    perPage: 50,
                                    page: 1,
                                  }}
                                >
                                  {({ data, loading, error, fetchMore }) => {
                                    if (loading) {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Spin tip="Loading..." />
                                        </div>
                                      );
                                    }
                                    if (error) {
                                      return <div>Error</div>;
                                    }
                                    const {
                                      totalDistanceParticipantStat,
                                    } = data;
                                    const totalDistanceParticipant =
                                      totalDistanceParticipantStat.items;
                                    return (
                                      <Row
                                        type="flex"
                                        justify="center"
                                        style={{
                                          backgroundColor: "#FAFAFA",
                                          padding: "20px 0px",
                                        }}
                                      >
                                        {chartsMode === "region" && (
                                          <Col
                                            xs={20}
                                            style={{ margin: "20px 0px" }}
                                          >
                                            <Row type="flex" justify="end">
                                              <Col xs={12} lg={5}>
                                                <Select
                                                  defaultValue=""
                                                  value={
                                                    this.state.selectedRegions
                                                  }
                                                  placeholder="ภูมิภาค"
                                                  style={{
                                                    width: "100%",
                                                    maxWidth: 200,
                                                  }}
                                                  onChange={
                                                    this.handleChangeRegions
                                                  }
                                                >
                                                  <Select.Option
                                                    key=""
                                                    value=""
                                                  >
                                                    ภูมิภาค ( ทั้งหมด )
                                                  </Select.Option>
                                                  {regions.map((regions) => (
                                                    <Select.Option
                                                      key={regions}
                                                      value={_get(
                                                        regions,
                                                        "name.en"
                                                      )}
                                                    >
                                                      {regions.name.th}
                                                    </Select.Option>
                                                  ))}
                                                </Select>
                                              </Col>
                                              <Col xs={12} lg={4}>
                                                <Select
                                                  defaultValue=""
                                                  placeholder="จังหวัด"
                                                  value={
                                                    this.state.selectedProvinces
                                                  }
                                                  style={{
                                                    width: "100%",
                                                    maxWidth: 180,
                                                  }}
                                                  onChange={
                                                    this.handleChangeProvinces
                                                  }
                                                >
                                                  <Select.Option
                                                    key=""
                                                    value=""
                                                  >
                                                    จังหวัด ( ทั้งหมด )
                                                  </Select.Option>
                                                  {provinces.map(
                                                    (provinces) => (
                                                      <Select.Option
                                                        key={provinces}
                                                        value={_get(
                                                          provinces,
                                                          "name.en"
                                                        )}
                                                      >
                                                        {provinces.name.th}
                                                      </Select.Option>
                                                    )
                                                  )}
                                                </Select>
                                              </Col>
                                            </Row>
                                          </Col>
                                        )}
                                        <Col xs={20}>
                                          <Row
                                            type="flex"
                                            justify="start"
                                            style={{ marginBottom: "20px" }}
                                          >
                                            {!isLineLogin && (
                                              <Col xs={24} lg={24}>
                                                <Row
                                                  type="flex"
                                                  justify="end"
                                                ></Row>
                                              </Col>
                                            )}
                                          </Row>
                                        </Col>
                                        <Col xs={24}>
                                          <Tabs
                                            onChange={this.handleTabs}
                                            defaultActiveKey={"All"}
                                            activeKey={
                                              this.state.activeKey === ""
                                                ? "All"
                                                : this.state.activeKey
                                            }
                                          >
                                            {chartsMode !== "region" && (
                                              <TabPane
                                                tab="All Runners"
                                                key="All"
                                              >
                                                <Row>
                                                  <Col xs={24}>
                                                    <Normal
                                                      rankMode = {rankMode}
                                                      eventGender = {eventGender}
                                                      regFields={regFields}
                                                      chartsMode={chartsMode}
                                                      ageRanges={ageRanges}
                                                      eventId={event._id}
                                                      raceId=""
                                                      nameRace="All"
                                                      timeDay={timeDay}
                                                      timeDayEnd={timeDayEnd}
                                                      totalDistanceAll={
                                                        totalRunDistance
                                                      }
                                                      totalRunScoreAll={
                                                        totalRunScoreAll
                                                      }
                                                      distanceUnitAll={
                                                        slug === "ChapterOfSwim"
                                                          ? "เมตร"
                                                          : _get(event, [
                                                              "races",
                                                              0,
                                                              "distanceUnit",
                                                            ])
                                                      }
                                                      totalDistanceParticipant={
                                                        totalDistanceParticipant
                                                      }
                                                      slug={event.slug}
                                                      hideSlug={hideSlug}
                                                      isLineLogin={isLineLogin}
                                                      userCount={
                                                        totalDistanceParticipantStat.count
                                                      }
                                                      fetchMore={fetchMore}
                                                    />
                                                  </Col>
                                                </Row>
                                              </TabPane>
                                            )}
                                            {chartsMode !== "region" &&
                                            raceTeam &&
                                            sumDistanceByGroupEvent &&
                                            sumDistanceByGroupEvent.length !==
                                              0 ? (
                                              <TabPane
                                                tab="All Teams"
                                                key="AllTeam"
                                              >
                                                <Row>
                                                  <Col xs={24}>
                                                    <Normal
                                                      rankMode = {rankMode}
                                                      eventGender = {eventGender}
                                                      regFields={regFields}
                                                      chartsMode={chartsMode}
                                                      ageRanges={ageRanges}
                                                      eventId={event._id}
                                                      raceId=""
                                                      nameRace="AllTeam"
                                                      timeDay={timeDay}
                                                      timeDayEnd={timeDayEnd}
                                                      totalDistanceAll={
                                                        totalRunDistance
                                                      }
                                                      totalRunScoreAll={
                                                        totalRunScoreAll
                                                      }
                                                      distanceUnitAll={
                                                        slug === "ChapterOfSwim"
                                                          ? "เมตร"
                                                          : "km"
                                                      }
                                                      totalDistanceParticipant={
                                                        totalDistanceParticipant
                                                      }
                                                      sumDistanceByGroup={
                                                        sumDistanceByGroupEvent
                                                      }
                                                      slug={event.slug}
                                                      hideSlug={hideSlug}
                                                      isLineLogin={isLineLogin}
                                                      registrationTypes={
                                                        registrationTypes
                                                      }
                                                      handleTabs={
                                                        this.handleTabs
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                              </TabPane>
                                            ) : (
                                              ""
                                            )}
                                            {chartsMode !== "race" &&
                                            chartsMode !== "regTypeTab" &&
                                            races &&
                                            races.length !== 0
                                              ? races &&
                                                _sortBy(races, (r) =>
                                                  _get(r, "order")
                                                )
                                                  .filter(
                                                    (r) => !_get(r, "hidden")
                                                  )
                                                  .map((race) => {
                                                    if (
                                                      !sumDistanceByGroupEvent
                                                    )
                                                      return "";
                                                    if (
                                                      sumDistanceByGroupEvent.length ===
                                                      0
                                                    )
                                                      return "";
                                                    const result = sumDistanceByGroupEvent.find(
                                                      (result) =>
                                                        _get(
                                                          result,
                                                          "raceId"
                                                        ) === race._id
                                                    );
                                                    let name = _get(
                                                      race,
                                                      "name.en"
                                                    );
                                                    const distanceUnit = _get(
                                                      race,
                                                      "distanceUnit"
                                                    );
                                                    const totalDistance = result
                                                      ? parseFloat(
                                                          result.totalDistance.toFixed(
                                                            2
                                                          )
                                                        ).toLocaleString()
                                                      : 0;
                                                    const raceId = race._id;
                                                    const imageUrl =
                                                      race.imageUrl;
                                                    return (
                                                      <TabPane
                                                        tab={
                                                          <span>
                                                            {imageUrl ? (
                                                              <img
                                                                src={imageUrl}
                                                                alt="imageUrlRace"
                                                                style={{
                                                                  width: "100%",
                                                                  maxWidth:
                                                                    "30px",
                                                                }}
                                                              />
                                                            ) : (
                                                              ""
                                                            )}
                                                            {name}
                                                          </span>
                                                        }
                                                        key={raceId}
                                                      >
                                                        {chartsMode ===
                                                        "region" ? (
                                                          <React.Fragment>
                                                            {!this.state
                                                              .charts && (
                                                              <RegionsChart
                                                                eventId={
                                                                  event._id
                                                                }
                                                                raceId={raceId}
                                                                distanceUnit={
                                                                  distanceUnit
                                                                }
                                                                startDate={
                                                                  this.state
                                                                    .startDate
                                                                }
                                                                endDate={
                                                                  this.state
                                                                    .endDate
                                                                }
                                                              />
                                                            )}
                                                            <Region
                                                              eventId={
                                                                event._id
                                                              }
                                                              raceId={raceId}
                                                              province={
                                                                this.state
                                                                  .selectedProvinces
                                                              }
                                                              region={
                                                                this.state
                                                                  .selectedRegions
                                                              }
                                                              distanceUnit={
                                                                distanceUnit
                                                              }
                                                              handleFilterGender={
                                                                this
                                                                  .handleFilterGender
                                                              }
                                                              startDate={
                                                                this.state
                                                                  .startDate
                                                              }
                                                              endDate={
                                                                this.state
                                                                  .endDate
                                                              }
                                                              isLineLogin={
                                                                isLineLogin
                                                              }
                                                            />
                                                          </React.Fragment>
                                                        ) : (
                                                          <React.Fragment>
                                                            <Row>
                                                              <Col xs={24}>
                                                                <Normal
                                                                rankMode = {rankMode}
                                                                eventGender={eventGender}
                                                                  regFields={
                                                                    regFields
                                                                  }
                                                                  chartsMode={
                                                                    chartsMode
                                                                  }
                                                                  ageRanges={
                                                                    ageRanges
                                                                  }
                                                                  eventId={
                                                                    event._id
                                                                  }
                                                                  raceId={
                                                                    raceId
                                                                  }
                                                                  nameRace={
                                                                    name
                                                                  }
                                                                  timeDay={
                                                                    timeDay
                                                                  }
                                                                  timeDayEnd={
                                                                    timeDayEnd
                                                                  }
                                                                  totalDistanceAll={
                                                                    totalDistance
                                                                  }
                                                                  // totalRunScoreAll={
                                                                  //   totalScore
                                                                  // }
                                                                  distanceUnitAll={
                                                                    distanceUnit
                                                                  }
                                                                  totalDistanceParticipant={
                                                                    totalDistanceParticipant
                                                                  }
                                                                  slug={
                                                                    event.slug
                                                                  }
                                                                  hideSlug={
                                                                    hideSlug
                                                                  }
                                                                  isLineLogin={
                                                                    isLineLogin
                                                                  }
                                                                  race={race}
                                                                />
                                                              </Col>
                                                            </Row>
                                                          </React.Fragment>
                                                        )}
                                                      </TabPane>
                                                    );
                                                  })
                                              : ""}
                                            {chartsMode === "regTypeTab" &&
                                            registrationTypes &&
                                            registrationTypes.length !== 0
                                              ? registrationTypes &&
                                                _sortBy(
                                                  registrationTypes,
                                                  (r) => _get(r, "order")
                                                ).map((race) => {
                                                  if (!sumDistanceByGroupEvent)
                                                    return "";
                                                  if (
                                                    sumDistanceByGroupEvent.length ===
                                                    0
                                                  )
                                                    return "";
                                                  const result = sumDistanceByGroupEvent.find(
                                                    (result) =>
                                                      _get(result, "raceId") ===
                                                      race._id
                                                  );
                                                  let name = _get(
                                                    race,
                                                    "name.en"
                                                  );
                                                  const distanceUnit = _get(
                                                    race,
                                                    "distanceUnit"
                                                  );
                                                  const totalDistance = result
                                                    ? parseFloat(
                                                        result.totalDistance.toFixed(
                                                          2
                                                        )
                                                      ).toLocaleString()
                                                    : 0;
                                                  const totalScore = result
                                                  ? parseFloat(
                                                      result.totalRunningScoreAll?.toFixed(
                                                        2
                                                      )
                                                    ).toLocaleString()
                                                  : 0;
                                                  const raceId = race._id;
                                                  const imageUrl =
                                                    race.imageUrl;
                                                  if (
                                                    name ===
                                                    "สมัครโดยใช้โค้ดพิเศษ"
                                                  ) {
                                                    return;
                                                  }
                                                  return (
                                                    <TabPane
                                                      tab={
                                                        <span>
                                                          {imageUrl ? (
                                                            <img
                                                              src={imageUrl}
                                                              alt="imageUrlRace"
                                                              style={{
                                                                width: "100%",
                                                                maxWidth:
                                                                  "30px",
                                                              }}
                                                            />
                                                          ) : (
                                                            ""
                                                          )}
                                                          {name}
                                                        </span>
                                                      }
                                                      key={raceId}
                                                    >
                                                      {chartsMode ===
                                                      "region" ? (
                                                        <React.Fragment>
                                                          {!this.state
                                                            .charts && (
                                                            <RegionsChart
                                                              eventId={
                                                                event._id
                                                              }
                                                              raceId={raceId}
                                                              distanceUnit={
                                                                distanceUnit
                                                              }
                                                              startDate={
                                                                this.state
                                                                  .startDate
                                                              }
                                                              endDate={
                                                                this.state
                                                                  .endDate
                                                              }
                                                            />
                                                          )}
                                                          <Region
                                                            eventId={event._id}
                                                            raceId={raceId}
                                                            province={
                                                              this.state
                                                                .selectedProvinces
                                                            }
                                                            region={
                                                              this.state
                                                                .selectedRegions
                                                            }
                                                            distanceUnit={
                                                              distanceUnit
                                                            }
                                                            handleFilterGender={
                                                              this
                                                                .handleFilterGender
                                                            }
                                                            startDate={
                                                              this.state
                                                                .startDate
                                                            }
                                                            endDate={
                                                              this.state.endDate
                                                            }
                                                            isLineLogin={
                                                              isLineLogin
                                                            }
                                                          />
                                                        </React.Fragment>
                                                      ) : (
                                                        <React.Fragment>
                                                          <Row>
                                                            <Col xs={24}>
                                                              <Normal
                                                                rankMode = {rankMode}
                                                                eventGender={eventGender}
                                                                regFields={
                                                                  regFields
                                                                }
                                                                chartsMode={
                                                                  chartsMode
                                                                }
                                                                ageRanges={
                                                                  ageRanges
                                                                }
                                                                eventId={
                                                                  event._id
                                                                }
                                                                raceId={raceId}
                                                                nameRace={name}
                                                                timeDay={
                                                                  timeDay
                                                                }
                                                                timeDayEnd={
                                                                  timeDayEnd
                                                                }
                                                                totalDistanceAll={
                                                                  totalDistance
                                                                }
                                                                totalRunScoreAll={
                                                                  totalScore
                                                                }
                                                                distanceUnitAll={
                                                                  distanceUnit
                                                                }
                                                                slug={
                                                                  event.slug
                                                                }
                                                                hideSlug={
                                                                  hideSlug
                                                                }
                                                                isLineLogin={
                                                                  isLineLogin
                                                                }
                                                                race={race}
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </React.Fragment>
                                                      )}
                                                    </TabPane>
                                                  );
                                                })
                                              : ""}
                                            {chartsMode === "race" &&
                                            sumDistanceByGroupEvent &&
                                            sumDistanceByGroupEvent.length !== 0
                                              ? sumDistanceByGroupEvent &&
                                                _sortBy(
                                                  sumDistanceByGroupEvent,
                                                  (result) => {
                                                    const race = event.races.find(
                                                      (r) =>
                                                        `${r._id}` ===
                                                        `${result.raceId}`
                                                    );
                                                    return _get(
                                                      race,
                                                      "order",
                                                      0
                                                    );
                                                  }
                                                ).map((result) => {
                                                  let name = _get(
                                                    result,
                                                    "name.en"
                                                  );
                                                  const distanceUnit =
                                                    result.distanceUnit;
                                                  const totalDistance = parseFloat(
                                                    result.totalDistance.toFixed(
                                                      2
                                                    )
                                                  ).toLocaleString();
                                                  const raceId = result.raceId;
                                                  const race = event.races.find(
                                                    (r) =>
                                                      `${r._id}` === `${raceId}`
                                                  );
                                                  const imageUrl =
                                                    result.imageUrl;
                                                  if (isLineLogin) {
                                                    name =
                                                      race.distance === 30
                                                        ? "ทีมผู้ปกครอง"
                                                        : "ทีมนักเรียน";
                                                  }
                                                  return (
                                                    <TabPane
                                                      tab={
                                                        <span>
                                                          {imageUrl ? (
                                                            <img
                                                              src={imageUrl}
                                                              alt="imageUrlRace"
                                                              style={{
                                                                width: "100%",
                                                                maxWidth:
                                                                  "30px",
                                                              }}
                                                            />
                                                          ) : (
                                                            ""
                                                          )}
                                                          {name}
                                                        </span>
                                                      }
                                                      key={raceId}
                                                    >
                                                      {chartsMode ===
                                                      "region" ? (
                                                        <React.Fragment>
                                                          {!this.state
                                                            .charts && (
                                                            <RegionsChart
                                                              eventId={
                                                                event._id
                                                              }
                                                              raceId={raceId}
                                                              distanceUnit={
                                                                distanceUnit
                                                              }
                                                              startDate={
                                                                this.state
                                                                  .startDate
                                                              }
                                                              endDate={
                                                                this.state
                                                                  .endDate
                                                              }
                                                            />
                                                          )}
                                                          <Region
                                                            eventId={event._id}
                                                            raceId={raceId}
                                                            province={
                                                              this.state
                                                                .selectedProvinces
                                                            }
                                                            region={
                                                              this.state
                                                                .selectedRegions
                                                            }
                                                            distanceUnit={
                                                              distanceUnit
                                                            }
                                                            handleFilterGender={
                                                              this
                                                                .handleFilterGender
                                                            }
                                                            startDate={
                                                              this.state
                                                                .startDate
                                                            }
                                                            endDate={
                                                              this.state.endDate
                                                            }
                                                            isLineLogin={
                                                              isLineLogin
                                                            }
                                                          />
                                                        </React.Fragment>
                                                      ) : (
                                                        <React.Fragment>
                                                          <Row>
                                                            <Col xs={24}>
                                                              <Normal
                                                                rankMode = {rankMode}
                                                                eventGender={eventGender}
                                                                regFields={
                                                                  regFields
                                                                }
                                                                chartsMode={
                                                                  chartsMode
                                                                }
                                                                ageRanges={
                                                                  ageRanges
                                                                }
                                                                eventId={
                                                                  event._id
                                                                }
                                                                raceId={raceId}
                                                                nameRace={name}
                                                                timeDay={
                                                                  timeDay
                                                                }
                                                                timeDayEnd={
                                                                  timeDayEnd
                                                                }
                                                                totalDistanceAll={
                                                                  totalDistance
                                                                }
                                                                totalRunScoreAll={
                                                                  totalScore
                                                                }
                                                                distanceUnitAll={
                                                                  distanceUnit
                                                                }
                                                                totalDistanceParticipant={
                                                                  totalDistanceParticipant
                                                                }
                                                                slug={
                                                                  event.slug
                                                                }
                                                                hideSlug={
                                                                  hideSlug
                                                                }
                                                                isLineLogin={
                                                                  isLineLogin
                                                                }
                                                                race={race}
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </React.Fragment>
                                                      )}
                                                    </TabPane>
                                                  );
                                                })
                                              : ""}
                                          </Tabs>
                                        </Col>
                                      </Row>
                                    );
                                  }}
                                </Query>
                              </React.Fragment>
                            );
                          }}
                        </Query>
                      )}
                    </FinisherWallCumulative>
                  </div>
                  {!hideSlug.includes(event.slug) && !isLineLogin ? (
                    <div style={{ backgroundColor: "#FAFAFA" }}>
                      <Query
                        query={eventRunsQuery}
                        client={raceClient}
                        variables={{
                          eventId: event._id,
                          perPage: 200,
                          page,
                        }}
                      >
                        {({ data, loading, error, fetchMore }) => {
                          if (loading) {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Spin tip="Loading..." />
                              </div>
                            );
                          }
                          if (error) {
                            return <div>Error</div>;
                          }
                          if (!data.raceRuns) {
                            return <div>Runs not found</div>;
                          }
                          const { raceRuns } = data;
                          const runItemsNotUndefined = _get(
                            raceRuns,
                            "items",
                            []
                          );
                          const filterRace = runItemsNotUndefined.filter(
                            (data) => {
                              if (chartsMode === "regTypeTab") return true;
                              if (this.state.raceId) {
                                if (
                                  this.state.raceId === "All" ||
                                  this.state.raceId === "AllTeam"
                                ) {
                                  return true;
                                } else {
                                  return (
                                    `${data.raceId}` === `${this.state.raceId}`
                                  );
                                }
                              } else {
                                return (
                                  `${data.raceId}` !== `${this.state.raceId}`
                                );
                              }
                            }
                          );
                          const filterProvinces = filterRace.filter((data) => {
                            if (this.state.selectedProvinces) {
                              return (
                                _get(data, "participant.profile.province") ===
                                this.state.selectedProvinces
                              );
                            } else {
                              return (
                                _get(data, "participant.profile.province") !==
                                this.state.selectedProvinces
                              );
                            }
                          });
                          let runItems;
                          const filterApproved = filterProvinces.filter(
                            (run) => {
                              return run.bibNumber !== "undefined";
                            }
                          );
                          if (!isAutoApproved) {
                            runItems = filterApproved.filter((run) => {
                              return run.status === "approved";
                            });
                          } else {
                            runItems = filterApproved;
                          }

                          return (
                            <React.Fragment>
                              <Tableevent>
                                <div className="imageGallery">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {runItems.length !== 0 ? (
                                      runItems.map((run, index) => {
                                        const distance = run.distance;
                                        const bibnumber = run.bibNumber;
                                        const runnerThumbnail = _get(
                                          run,
                                          "runnerImage.thumbnail"
                                        );
                                        const activityThumbnail = _get(
                                          run,
                                          "activityImage.thumbnail"
                                        );
                                        const thumbnail =
                                          runnerThumbnail || activityThumbnail;
                                        return thumbnail ? (
                                          <div
                                            className="imageItem item"
                                            key={index}
                                            onClick={this.handleRunClick(run)}
                                            style={{
                                              backgroundImage: `url(${_get(
                                                thumbnail,
                                                "url"
                                              )})`,
                                            }}
                                          >
                                            <div className="item-overlay top">
                                              <p className="font-item">
                                                BiB: {bibnumber}{" "}
                                              </p>
                                              <p className="font-item-distance">
                                                {t("finisherwall.distance")}:{" "}
                                                {distance} Km.
                                              </p>
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            key={index}
                                            style={{ display: "none" }}
                                          />
                                        );
                                      })
                                    ) : (
                                      <div
                                        style={{
                                          color: "#f9a01e",
                                          marginBottom: "30px",
                                        }}
                                      >
                                        ยังไม่มีการส่งผล กรุณาส่งผล{" "}
                                        <a
                                          href={`/${slug}/submit`}
                                          alt="กรุณาส่งผล"
                                          rel="noopener noreferrer"
                                        >
                                          {" "}
                                          คลิก!!!{" "}
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {runItems.length >= 200 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginBottom: "20px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <Button
                                      onClick={() => {
                                        fetchMore({
                                          variables: {
                                            eventId: event._id,
                                            perPage: PER_PAGE,
                                            page:
                                              Math.ceil(
                                                raceRuns.items.length / PER_PAGE
                                              ) + 1,
                                          },
                                          updateQuery: (
                                            previousResult,
                                            { fetchMoreResult }
                                          ) => {
                                            return {
                                              raceRuns: {
                                                ...previousResult.raceRuns,
                                                items: previousResult.raceRuns.items.concat(
                                                  fetchMoreResult.raceRuns.items
                                                ),
                                              },
                                            };
                                          },
                                        });
                                      }}
                                    >
                                      More ...
                                    </Button>
                                  </div>
                                )}
                              </Tableevent>
                            </React.Fragment>
                          );
                        }}
                      </Query>
                    </div>
                  ) : (
                    ""
                  )}

                  <div>{!isLineLogin && <Footer />}</div>
                </div>
              </div>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

export default compose(withTracker, withTranslation())(Cumulative);
